import React, { useEffect, useState, useMemo } from 'react'
import { Box, Button, Card, Grid, Typography } from '@mui/material'
import { useDataProvider, useRedirect, useShowController } from 'react-admin'
import { formatDate, HorizontalCardMedia } from '@thefront/pandipackV2'
import RunsList from '../Runs/RunsList'
import { useThemeConfig } from '../../themeConfig'

const logStyles = {
    root: {
        '& .MuiCard-root': {
            boxShadow: 'none',
        },
        width: '100%',
    },
    actionGrid: {
        display: 'flex',
        height: '4%',
        margin: '24px',
    },
    card: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%',
        textAlign: 'left',
    },
    heading1: {
        margin: '48px 0 0 8px',
        fontSize: '24 px',
    },
    listButton: (theme) => ({
        height: '40px',
        color: theme.palette.secondary.main,
    }),
    loadingTextContainer: {
        margin: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    linearProgress: {
        width: '40%',
    },
    heading: {
        margin: '36px',
    },
}

export default () => {
    const { configs } = useThemeConfig()
    const { marketplaceSettings, dateFormat } = configs

    const { record, isLoading } = useShowController()

    const [tenant, setTenant] = useState(!isLoading ? record : undefined)

    const dataProvider = useDataProvider()
    const tenantCallback = (event) => {
        setTenant(event.payload)
    }

    useEffect(() => {
        if (record) {
            dataProvider.subscribe(`tenants/${record.id}`, tenantCallback)
            return () =>
                dataProvider.unsubscribe(`tenants/${record.id}`, tenantCallback)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record])

    const lastRun = formatDate(
        tenant?.status?.lastRun?.completionTime,
        false,
        dateFormat
    )
    const redirect = useRedirect()

    return useMemo(() => {
        return (
            tenant && (
                <Box
                    className={'pandium-logs-page'}
                    sx={[
                        logStyles.root,
                        {
                            maxWidth: marketplaceSettings?.fullWidthMarketplace
                                ? 'none'
                                : '1280px',
                        },
                    ]}
                >
                    <Grid
                        item
                        xs={12}
                        sx={logStyles.actionGrid}
                        className={'pandium-header'}
                    >
                        <Button
                            className={'pandium-back-button'}
                            onClick={() =>
                                redirect(
                                    marketplaceSettings?.separateApps
                                        ? '/tenants'
                                        : '/integrations'
                                )
                            }
                        >
                            {marketplaceSettings?.separateApps
                                ? '< My apps'
                                : '< My apps & integrations'}
                        </Button>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={3}>
                            <HorizontalCardMedia
                                src={
                                    tenant.integration?.marketplace_settings
                                        ?.primaryLogo
                                }
                            />
                        </Grid>
                        <Grid item xs={6} sx={logStyles.heading}>
                            <Typography variant="h4">
                                {tenant.integration?.long_name}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Card sx={logStyles.card}>
                        <Typography variant="h5" sx={logStyles.heading1}>
                            {' '}
                            Sync logs{' '}
                        </Typography>
                        <RunsList tenant={tenant} />
                    </Card>
                </Box>
            )
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastRun, tenant])
}
