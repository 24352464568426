import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Typography, Box } from '@mui/material'
import { isEmpty, get } from 'lodash-es'
import ConnectDialog from './ConnectDialog'
import { connect } from 'react-redux'
import { useConnectors, isBackgroundConnect } from '../componentHelpers'
import { isConnectorConnected, trackUmamiEvent } from '@thefront/pandipackV2'
import { DisconnectDialog } from '../common'
import { useThemeConfig } from '../../themeConfig'
import { useDataProvider, useNotify } from 'react-admin'
import { POPUP_WINDOW_PARAMS } from '../../themeConfig'
// TODO this is custom for kombo..
import { showKomboConnect } from '@kombo-api/connect'

export const ConnectForm = ({ connector, tenant, user }) => {
    const dataProvider = useDataProvider()
    const notify = useNotify()

    const [openConnectDialog, setOpenConnectDialog] = useState(false)
    const [openDisconnectDialog, setOpenDisconnectDialog] = useState(false)
    const [connect, setConnect] = useState(true)

    //state for connector dialogues
    const [modalClose, setModalClose] = useState(true)
    const [oAuth2Link, setOauth2Link] = useState('')
    const [modalError, setModalError] = useState('')
    const [modalLoading, setModalLoading] = useState(false)

    // TODO this is custom for kombo..
    const [komboToken, setKomboToken] = useState('')

    useEffect(() => {
        if (!isEmpty(komboToken)) {
            connectBasic('kombo', { token: komboToken })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [komboToken])

    const connectBasicSuccess = () => {
        setModalError('')
        setModalLoading(false)
        setModalClose(true)
        console.debug('Connect basic success:', connector.name)
        notify('Save success!')
    }

    const connectBasicFailure = (error) => {
        setModalError(error)
        setModalLoading(false)
        setModalClose(false)
    }

    const connectBasicLoading = () => {
        setModalError('')
        setModalLoading(true)
        setModalClose(false)
    }

    const resetModalState = () => {
        setModalError('')
        setModalLoading(false)
        setModalClose(true)
    }

    const connectRequest = (connectorName, form_data = undefined) => {
        dataProvider
            .AUTHOR('author/connect_tenant', {
                data: {
                    connector_name: connectorName,
                    integration_name: tenant.integration.name,
                    tenant_id: tenant.id,
                    form_data,
                },
            })
            .then(async (res) => {
                if (connectorName === 'kombo') {
                    setKomboToken(await showKomboConnect(res.data.link))
                } else if (res.data.link) {
                    console.debug('Opening new tab... ', res.data.link)
                    window.open(res.data.link, null, POPUP_WINDOW_PARAMS)
                } else {
                    //look for install URL / custom linnworks login flow
                    setOauth2Link(res.data.installUrl)
                }
            })
    }

    const connectBasic = (connectorName, values) => {
        connectBasicLoading()
        dataProvider
            .AUTHOR('author/callback/basic', {
                data: {
                    connector_name: connectorName,
                    integration_name: tenant.integration.name,
                    tenant_id: tenant.id,
                    secrets: values,
                },
            })
            .then((res) => {
                connectBasicSuccess()
            })
            .catch((err) => {
                console.debug(err)
                connectBasicFailure(err.message.detail || err.message)
            })
    }

    const disconnectRequest = (connectorName) => {
        dataProvider
            .AUTHOR('author/disconnect_tenant', {
                data: {
                    connector_name: connectorName,
                    integration_name: tenant.integration.name,
                    tenant_id: tenant.id,
                },
            })
            .then(() => {
                console.debug('Disconnect success: ', connector.name)
                notify('Disconnect success!')
            })
    }

    // Redux will show modalClose as true when a successful connection is created. We must update our local state here.
    useEffect(() => {
        if (modalClose) {
            handleCloseConnectDialog()
        }
    }, [modalClose])

    // if anytime the connection dialog close, we must clear the redux modal state.
    useEffect(() => {
        if (!openConnectDialog) {
            resetModalState()
        }
    }, [openConnectDialog])

    // Redirect to oauth2 link if applicable, else render the connector form
    const handleOpenConnectDialog = () => {
        if (
            connector.type !== 'basic' &&
            get(connector, 'metadata.multi_step') !== true &&
            ['web', 'hybrid', 'custom'].includes(
                get(connector, 'metadata.grant_flow')
            )
        ) {
            connectRequest(connector.name)
        } else if (connector.name === 'whiplash' && user?.xti?.customer_id) {
            // TODO: remove after generalizing this functionality
            // special whiplash IMP behavior to autofill the customer_id from the xti
            connectRequest(connector.name, {
                customer_id: user.xti.customer_id.toString(),
            })
        } else {
            setOpenConnectDialog(true)
        }
        if (get(connector, 'metadata.grant_flow') === 'custom') {
            setOpenConnectDialog(true)
        }
        trackUmamiEvent(
            `user: ${user.userName}, ${get(tenant, 'integration.name')} - ${
                connector.name
            }`,
            'Connect',
            tenant.id,
            'tenants',
            user.userID
        )
    }

    const handleCloseConnectDialog = () => {
        setOpenConnectDialog(false)
    }

    const handleOpenDisconnectDialog = () => {
        setOpenDisconnectDialog(true)
    }

    const handleCloseDisconnectDialog = () => {
        setOpenDisconnectDialog(false)
    }

    const disconnect = () => {
        trackUmamiEvent(
            `user: ${user.userName}, ${get(tenant, 'integration.name')} - ${
                connector.name
            }`,
            'Disconnect',
            tenant.id,
            'tenants',
            user.userID
        )
        disconnectRequest(connector.name)
        setOpenConnectDialog(false)
        setConnect(true)
    }

    const getUsernameDisplay = (tenant, connector) => {
        const identifier =
            connector.metadata?.user_identifier?.tenant_label_key ||
            connector.name

        return tenant.connectedUsers[identifier]?.username
    }

    const connectorLabel = connector.label || connector.id
    const isConnected = isConnectorConnected(tenant, connector.name)

    return (
        <div role={'form'}>
            <Box
                className="pandium-connector-box"
                sx={{
                    height: '66px',
                    maxWidth: '750px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '5px',
                    margin: '0px 2px 30px',
                    boxShadow:
                        '0 0 0 1px #b8bbc185, 0 0 0 2px #dbdee5c7 !important',
                    borderRadius: '3px',
                    backgroundColor: 'rgb(250,250,250)',
                }}
            >
                <Box
                    sx={{
                        height: '24px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '10px',
                    }}
                >
                    <Box
                        className="pandium-logo"
                        component="img"
                        src={connector.logoUrl}
                        alt=""
                        sx={{
                            height: '50px',
                            width: '50px',
                        }}
                    />
                    <Typography
                        className="pandium-name"
                        sx={{
                            fontSize: '20px',
                            fontWeight: '500',
                            letterSpacing: '0.25px',
                            lineHeight: '24px',
                            textTransform: 'capitalize',
                        }}
                    >
                        {connectorLabel}
                    </Typography>
                    {isConnected &&
                        (getUsernameDisplay(tenant, connector) ? (
                            <Typography
                                className="pandium-text"
                                sx={{
                                    mt: '2px',
                                }}
                                variant="body1"
                            >
                                Connected as{' '}
                                {getUsernameDisplay(tenant, connector)}{' '}
                            </Typography>
                        ) : (
                            <Typography
                                className="pandium-text"
                                sx={{
                                    mt: '2px',
                                }}
                                variant="body1"
                            >
                                Connected
                            </Typography>
                        ))}
                </Box>
                {connect && !isConnected ? (
                    <Button
                        className="pandium-button"
                        aria-label={'Connect tenant'}
                        children="Connect"
                        sx={(theme) => ({
                            color: theme.palette.primary.main,
                            fontSize: '14px',
                            fontWeight: '500',
                            letterSpacing: '1.25px',
                            lineHeight: '16px',
                            borderRadius: '2',
                            padding: '5px 10px 5px 10px',
                            margin: '0 5px 0 5px',
                            '&:disabled': {
                                color: 'gray',
                            },
                            boxShadow: 'none',
                        })}
                        onClick={handleOpenConnectDialog}
                        variant="text"
                    />
                ) : (
                    <Button
                        aria-label={'Disconnect tenant'}
                        className="pandium-button"
                        children="Disconnect"
                        sx={(theme) => ({
                            color: theme.palette.primary.main,
                            fontSize: '14px',
                            fontWeight: '500',
                            letterSpacing: '1.25px',
                            lineHeight: '16px',
                            borderRadius: '2',
                            padding: '5px 10px 5px 10px',
                            margin: '0 5px 0 5px',
                            '&:disabled': {
                                color: 'gray',
                            },
                            boxShadow: 'none',
                        })}
                        onClick={handleOpenDisconnectDialog}
                        variant="text"
                    />
                )}
            </Box>
            <ConnectDialog
                close={handleCloseConnectDialog}
                open={openConnectDialog}
                connectorName={connector.name}
                connector={connector}
                tenant={tenant}
                oAuth2Link={oAuth2Link}
                modalError={modalError}
                modalLoading={modalLoading}
                connectRequest={connectRequest}
                connectBasic={connectBasic}
                mode="internal"
            />
            <DisconnectDialog
                handleClose={handleCloseDisconnectDialog}
                open={openDisconnectDialog}
                connector={connector}
                disconnect={disconnect}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const SingleConnectorForm = connect(mapStateToProps)(ConnectForm)

/**
 * Form that renders connect forms/dialogs
 *
 * @param props -- only requires the Tenant. It will extract the connectors, ids, integrationName, etc from it.
 * @returns {*}
 * @constructor
 */
export default (props) => {
    const { tenant } = props
    const connectors = useConnectors(tenant)
    const theme = useThemeConfig()

    return (
        <Box
            sx={{
                display: 'inline-block',
                width: '100%',
                marginTop: '20px',
            }}
        >
            {!isEmpty(connectors) ? (
                get(tenant, 'integration.connectors', []).map(
                    (tenantConnector) => {
                        if (
                            !tenantConnector.isGlobal &&
                            !isBackgroundConnect(
                                tenantConnector,
                                theme,
                                connectors
                            )
                        ) {
                            return (
                                <SingleConnectorForm
                                    connector={connectors.find(
                                        ({ name }) =>
                                            name === tenantConnector.name
                                    )}
                                    key={tenantConnector.name}
                                    tenant={tenant}
                                />
                            )
                        } else {
                            return null
                        }
                    }
                )
            ) : (
                <CircularProgress />
            )}
        </Box>
    )
}
