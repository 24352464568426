import React, { useEffect, useState } from 'react'
import { trackUmamiEvent } from '@thefront/pandipackV2'
import {
    Edit,
    BooleanInput,
    Form,
    useNotify,
    useRedirect,
    useDataProvider,
} from 'react-admin'
import { Box, Paper, Typography, Divider, Grid, Button } from '@mui/material'
import { connect } from 'react-redux'
import { useThemeConfig } from '../../themeConfig'
import { EditPageHeader } from './TenantEdit'
import { useFormContext } from 'react-hook-form'
import CronInput from '../../components/CronInput'
import { ContentBlock } from '../../components/common'
import cronstrue from 'cronstrue'

// Handle tenant scheduling
export const ScheduleTab = (props) => {
    const { tenant, theme } = props
    const integration = tenant?.integration
    const classes = {
        subtitle: {
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 0.54)',
            marginTop: '10px',
            marginBottom: '25px',
        },
        inputBox: {
            marginBottom: '61px',
        },
        pauseToggle: {
            marginTop: '10px',
            paddingLeft: '4px',
        },
    }
    const [humanCron, setHumanCron] = useState('')
    useEffect(() => {
        try {
            if (tenant?.paused || tenant?.schedule === 'paused') {
                setHumanCron('paused')
            } else if (tenant?.schedule) {
                setHumanCron(cronstrue.toString(tenant.schedule))
            } else {
                setHumanCron('')
            }
        } catch (err) {
            console.log(
                `Error parsing tenant schedule ${tenant?.schedule}: ${err}`
            )
            setHumanCron('')
        }
    }, [tenant])

    return (
        <Box className={'pandium-sync-schedule'}>
            <Typography variant={'h6'} className={'pandium-main-text'}>
                Schedule
            </Typography>
            {humanCron && (
                <Typography
                    sx={classes.subtitle}
                    className={'pandium-schedule-time'}
                >
                    Current schedule: {humanCron}
                </Typography>
            )}
            {integration?.marketplace_settings?.syncScheduleDescription ? (
                <ContentBlock
                    record={integration}
                    source="marketplace_settings.syncScheduleDescription"
                    sx={classes.subtitle}
                />
            ) : (
                <Typography sx={classes.subtitle} className={'pandium-text'}>
                    Select frequency of sync entities
                </Typography>
            )}
            <div style={classes.inputBox}>
                <CronInput
                    pauseInDropdown={
                        theme.configs.marketplaceSettings.jointCronSelector
                    }
                    integrationScheduleList={integration?.sync_schedule_times}
                />

                {!theme.configs.marketplaceSettings?.jointCronSelector && (
                    <BooleanInput
                        aria-label="pause toggle"
                        source="paused"
                        label="Pause sync schedule"
                        sx={classes.pauseToggle}
                    />
                )}
            </div>
        </Box>
    )
}

export const ScheduleFooter = ({ tenant, user, theme }) => {
    const redirect = useRedirect()
    const notify = useNotify()
    const values = useFormContext().getValues()

    const onSuccess = () => {
        trackUmamiEvent(
            `user: ${user.userName}, ${tenant.name}`,
            'Schedule',
            tenant.id,
            'tenants',
            user.userID
        )
        redirect(
            theme.configs.marketplaceSettings.separateApps ? '/tenants' : '/'
        )
    }

    const dataProvider = useDataProvider()
    const update = () =>
        dataProvider
            .update('tenants', { id: tenant.id, data: values })
            .then(() => onSuccess())
            .catch(() => notify('an error occured', { type: 'error' }))

    return (
        <div>
            <Divider />
            <Grid
                sx={{
                    margin: '33px 0 10px',
                    '& > button': {
                        marginRight: '32px',
                        width: '96px',
                    },
                }}
            >
                <Button
                    color="primary"
                    onClick={() => {
                        redirect(
                            theme.configs.marketplaceSettings.separateApps
                                ? '/tenants'
                                : '/'
                        )
                    }}
                    sx={{ textTransform: 'uppercase' }}
                >
                    Cancel
                </Button>
                <Button
                    sx={{ textTransform: 'uppercase' }}
                    variant="contained"
                    color="primary"
                    onClick={update}
                >
                    Save
                </Button>
            </Grid>
        </div>
    )
}

const SchedulePage = (props) => {
    const { tenant, id, resource, basePath, user } = props
    const theme = useThemeConfig()

    return (
        <Paper
            className={'pandium-schedule-page'}
            sx={(theme) => ({
                [theme.breakpoints.up('xs')]: {
                    width: '776px',
                },
                [theme.breakpoints.up('sm')]: {
                    width: '794px',
                },
            })}
            elevation={0}
        >
            <EditPageHeader integration={tenant.integration} />
            <Edit
                actions={null}
                id={id}
                resource={resource}
                basePath={basePath}
                undoable={false}
                component="div"
            >
                <Form>
                    <>
                        <Typography
                            variant={'h5'}
                            sx={{ textTransform: 'capitalize' }}
                        >
                            Sync schedule
                        </Typography>
                        <Divider
                            sx={{
                                marginTop: '17px',
                                marginBottom: '40px',
                            }}
                        />
                        <ScheduleTab theme={theme} tenant={tenant} />
                        <ScheduleFooter
                            tenant={tenant}
                            user={user}
                            theme={theme}
                        />
                    </>
                </Form>
            </Edit>
        </Paper>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
})

export default connect(mapStateToProps)(SchedulePage)
