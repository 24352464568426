// run statuses come from the platform in title case
/* eslint-disable sentence-case/sentence-case */
import React, { useRef, useEffect, useState } from 'react'
import {
    ListContextProvider,
    Datagrid,
    TextField,
    FunctionField,
    useRecordContext,
    useListController,
} from 'react-admin'
import { Box, CircularProgress, Typography, Grid } from '@mui/material'
import { CheckCircle, Error, ExpandMore, ExpandLess } from '@mui/icons-material'
import RunExpand from './RunExpand'
import { isEmpty, get } from 'lodash-es'
import ListPagination from '../List/ListPagination'
import { formatDate } from '@thefront/pandipackV2'
import { useThemeConfig } from '../../themeConfig'

const RUNS_PER_PAGE = 20

const runPhaseClasses = {
    initializing: { fontStyle: 'italic' },
    success: { color: '#7ED321' },
    error: { color: 'red', display: 'flex' },
    status: { padding: '0 20 0 0' },
}

const RunPhaseField = ({ source, addLabel = false }) => {
    const record = useRecordContext()
    switch (get(record, source)) {
        case 'In Progress':
        case 'Initializing':
            return (
                <span className={runPhaseClasses.initializing}>
                    In progress
                </span>
            )
        case 'Succeeded':
            return (
                <Grid container>
                    <Grid item xs={9}>
                        Complete
                    </Grid>
                    <Grid item xs={3}>
                        <CheckCircle sx={runPhaseClasses.success} />
                        {addLabel && record[source]}
                    </Grid>
                </Grid>
            )
        case 'Failed (Integration Issue)':
        case 'Failed (Platform Issue)':
        case 'Failed (Timeout)':
            return (
                <Grid container sx={runPhaseClasses.error}>
                    <Grid item xs={9} sx={runPhaseClasses.status}>
                        Error
                    </Grid>
                    <Grid item xs={3}>
                        <Error />
                        {addLabel && record[source]}
                    </Grid>
                </Grid>
            )
        default:
            return (
                <span className={runPhaseClasses.initializing}>
                    Initializing
                </span>
            )
    }
}

const IDField = ({ source, expand }) => {
    const record = useRecordContext()
    try {
        const idArray = get(record, source, '').split('-')
        return (
            <Typography
                variant="body1"
                color="primary"
                sx={{ display: 'flex', flexWrap: 'wrap' }}
            >
                {expand ? <ExpandLess /> : <ExpandMore />}
                <span style={{ paddingLeft: '2px' }}>
                    {idArray[idArray.length - 1].toUpperCase()}
                </span>
            </Typography>
        )
    } catch {
        return (
            <Typography variant="body1" color={'primary'}>
                {expand ? <ExpandLess /> : <ExpandMore />}
                {record[source]}
            </Typography>
        )
    }
}

const dataGridCSS = {
    headerCell: {
        height: '48px',
        fontSize: '20px',
        fontWeight: 'bold',
        color: 'black',
        paddingLeft: '8px',
    },
    rowCell: {
        height: '56px',
        textAlign: 'left',
        textTransform: 'capitalize',
        font: 'Roboto Regular',
        paddingLeft: '6px',
        fontSize: '16px',
    },
}

export const RunDataGrid = ({
    uidDisplay,
    bulkActionButtons,
    linkToTenant = true,
    ...props
}) => {
    const { configs } = useThemeConfig()
    const [rowExpandedState, setRowExpandedState] = useState(false)

    return (
        <Datagrid
            className={'pandium-logs-list'}
            {...props}
            data={props.data}
            expand={<RunExpand setExpand={setRowExpandedState} />}
            rowClick="expand"
            bulkActionButtons={false}
            sx={{
                '& .RaDatagrid-headerCell': dataGridCSS.headerCell,
                '& .RaDatagrid-rowCell': dataGridCSS.rowCell,
                '& .RaDatagrid-expandHeader': { display: 'none' },
                '& .RaDatagrid-expandIconCell': { display: 'none' },
            }}
        >
            <IDField
                source="name"
                sortable={false}
                label={'ID'}
                isRowExpandable={rowExpandedState}
            />
            <TextField
                className={classes.text}
                source="trigger"
                label="Trigger"
            />
            <FunctionField
                fontSize="16px"
                render={(record) =>
                    formatDate(record?.startedDate, false, configs.dateFormat)
                }
                label="Start time"
                sortBy="startedDate"
                sortByOrder="DESC"
            />
            <FunctionField
                fontSize="16px"
                render={(record) =>
                    formatDate(record?.completedDate, false, configs.dateFormat)
                }
                label="End time"
                sortBy="completedDate"
                sortByOrder="DESC"
            />
            <RunPhaseField source="status.phase" label="Status" />
        </Datagrid>
    )
}

const classes = {
    loadingTextContainer: {
        margin: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    loadingText: {
        margin: '25px 0',
        fontSize: '20px',
    },
}

export default ({ tenant, perPage = RUNS_PER_PAGE }) => {
    // Using one of RA's component to get necessary props to render ListView and Datagrid.
    const controllerProps = useListController({
        resource: 'runs',
        perPage: perPage,
        filter: { tenant_id: tenant.id },
        sort: { field: 'startedDate', order: 'DESC' },
    })

    // the parent component is polling the tenant. Here, we refresh the
    // list view whenever the tenant changes.
    const mounted = useRef(false)
    useEffect(() => {
        if (mounted.current) {
            controllerProps.refetch()
        } else {
            mounted.current = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenant])

    if (controllerProps.isLoading) {
        return <CircularProgress />
    }

    if (isEmpty(controllerProps.data)) {
        return (
            <Box sx={classes.loadingTextContainer}>
                <Box sx={classes.loadingText}>No activity yet</Box>
            </Box>
        )
    }

    return (
        <ListContextProvider value={controllerProps}>
            <RunDataGrid />
            <ListPagination />
        </ListContextProvider>
    )
}
