import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    CircularProgress,
    Link,
    Box,
    Typography,
} from '@mui/material'
import { truncate, get, isEmpty } from 'lodash-es'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FileCopy } from '@mui/icons-material'
import { JsonFormsWrapper } from '../jsonforms/JsonFormsWrapper'
import { Capitalize } from '@thefront/pandipackV2'
import { POPUP_WINDOW_PARAMS } from '../../themeConfig'

const ConnectDialog = ({
    close,
    connector,
    tenant,
    oAuth2Link,
    modalError,
    modalLoading,
    open,
    connectBasic,
    connectRequest,
    mode, // 'internal' (IMP) or 'external' (EMP)
}) => {
    const [connectorStep, setConnectorStep] = useState(1)
    const save = (connectorName, values) => {
        connectBasic(connectorName, values)
        if (!connector.metadata?.test_after_connect) {
            close()
        }
    }
    const next = (connectorName, values) => {
        connectRequest(connectorName, values)
        setConnectorStep(2)
        close()
    }

    const [secretValues, setSecretValues] = useState()
    const helpText = get(connector, 'helptext', false)
    const shouldRenderForm =
        get(connector, 'metadata.grant_flow') === 'backend' ||
        get(connector, 'metadata.grant_flow') === 'jwt' ||
        get(connector, 'metadata.grant_flow') === 'legacy' ||
        connector.type === 'basic' ||
        ['ezcom', 'field_nation', 'sage'].includes(connector.name)

    const [isJsonFormValid, setIsJsonFormValid] = useState(false)
    const onJsonFormChange = (errors, jsonFormValues) => {
        setSecretValues(jsonFormValues)
        isEmpty(errors) ? setIsJsonFormValid(true) : setIsJsonFormValid(false)
    }
    const connectDialogContents = (
        <>
            <DialogContent
                sx={{
                    width: '85%',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {helpText && (
                    <DialogContentText sx={{ mb: '8px' }}>
                        {helpText}
                    </DialogContentText>
                )}
                {modalError && (
                    <DialogContentText sx={{ mb: '8px' }} color="error">
                        Authentication Failed. Please review and try again.
                    </DialogContentText>
                )}
                {connector.metadata?.test_after_connect && modalLoading ? (
                    <Box sx={{ margin: 'auto' }}>
                        <CircularProgress />
                    </Box>
                ) : // Content differs based on connector type
                shouldRenderForm && !get(connector, 'jsonrender.schema') ? (
                    <>
                        {mode === 'external' && !modalError && !helpText && (
                            <Typography>
                                Thank you for your interest in the{' '}
                                {tenant.integration.name} integration! To
                                authenticate your account please enter in the
                                following credentials.
                            </Typography>
                        )}
                        <JsonFormsWrapper
                            schema={get(connector, 'jsonform.schema')}
                            uischema={get(connector, 'jsonform.uischema')}
                            data={secretValues}
                            onJsonFormChange={onJsonFormChange}
                            fullWidth={true}
                        />
                    </>
                ) : // Render content for oauth2
                (connector.type === 'oauth2' &&
                      get(connector, 'metadata.multi_step') !== true) ||
                  (get(connector, 'metadata.multi_step') === true &&
                      connectorStep === 2) ? (
                    // Render content for backend & legacy Oauth 2 connectors that take both tenant level secrets and integration level secrets
                    ['backend', 'legacy'].includes(
                        get(connector, 'metadata.grant_flow')
                    ) && get(connector, 'jsonrender.schema') ? (
                        <>
                            {mode === 'external' &&
                                !modalError &&
                                !helpText && (
                                    <Typography>
                                        Thank you for your interest in the{' '}
                                        {tenant.integration.name} integration!
                                        To authenticate your account please
                                        enter in the following credentials.
                                    </Typography>
                                )}
                            <JsonFormsWrapper
                                schema={get(connector, 'jsonrender.schema')}
                                uischema={get(connector, 'jsonrender.uischema')}
                                data={secretValues}
                                onJsonFormChange={onJsonFormChange}
                                fullWidth={true}
                            />
                        </>
                    ) : // Render the Oauth 2 link for all other Oauth2 connectors
                    oAuth2Link ? (
                        <div>
                            <CopyToClipboard text={oAuth2Link}>
                                <IconButton size="large">
                                    <FileCopy />
                                </IconButton>
                            </CopyToClipboard>
                            <Link
                                href={oAuth2Link}
                                onClick={(e) => {
                                    window.open(
                                        oAuth2Link,
                                        null,
                                        POPUP_WINDOW_PARAMS
                                    )
                                    e.preventDefault()
                                }}
                                sx={{ color: '#1976d2' }}
                                underline="hover"
                            >
                                {' '}
                                {truncate(oAuth2Link, {
                                    length: 40,
                                })}{' '}
                            </Link>
                        </div>
                    ) : (
                        <Box
                            sx={{
                                textAlign: 'center',
                                mt: '40px',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )
                ) : connector.name === 'linnworks' ? (
                    oAuth2Link ? (
                        <div>
                            <DialogContentText>
                                Please use the link below to generate your API
                                Key, and then pass that key into the form below.
                            </DialogContentText>
                            <CopyToClipboard text={oAuth2Link}>
                                <IconButton size="large">
                                    <FileCopy />
                                </IconButton>
                            </CopyToClipboard>
                            <Link
                                href={oAuth2Link}
                                onClick={(e) => {
                                    window.open(
                                        oAuth2Link,
                                        null,
                                        POPUP_WINDOW_PARAMS
                                    )
                                    e.preventDefault()
                                }}
                                sx={{ color: '#1976d2' }}
                                underline="hover"
                            >
                                {' '}
                                {truncate(oAuth2Link, {
                                    length: 40,
                                })}{' '}
                            </Link>
                            <JsonFormsWrapper
                                schema={get(connector, 'jsonrender.schema')}
                                uischema={get(connector, 'jsonrender.uischema')}
                                data={secretValues}
                                onJsonFormChange={onJsonFormChange}
                                fullWidth={true}
                            />
                        </div>
                    ) : (
                        <Box
                            sx={{
                                textAlign: 'center',
                                mt: '40px',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )
                ) : get(connector, 'jsonrender.schema') ? (
                    <>
                        {mode === 'external' && !modalError && !helpText && (
                            <Typography>
                                Thank you for your interest in the{' '}
                                {tenant.integration.name} integration! To
                                authenticate your account please enter in the
                                following credentials.
                            </Typography>
                        )}
                        <JsonFormsWrapper
                            schema={get(connector, 'jsonrender.schema')}
                            uischema={get(connector, 'jsonrender.uischema')}
                            data={secretValues}
                            onJsonFormChange={onJsonFormChange}
                            fullWidth={true}
                        />
                    </>
                ) : (
                    // For a different connector type?
                    <div> Under construction </div>
                )}
            </DialogContent>
            <DialogActions>
                {!modalLoading && (
                    <>
                        {mode === 'internal' && (
                            <Button onClick={close}>Cancel</Button>
                        )}
                        {(shouldRenderForm ||
                            connector.name === 'linnworks') && (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                    save(connector.name, secretValues)
                                    // connectBasicLoading()
                                }}
                                disabled={!isJsonFormValid}
                            >
                                Connect
                            </Button>
                        )}
                        {get(connector, 'metadata.multi_step') === true &&
                            (connectorStep === 1 ? (
                                <Button
                                    onClick={() => {
                                        next(connector.name, secretValues)
                                    }}
                                >
                                    Next
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => {
                                        setConnectorStep(1)
                                    }}
                                >
                                    Back
                                </Button>
                            ))}
                    </>
                )}
            </DialogActions>
        </>
    )

    return mode === 'internal' ? (
        <Dialog
            open={open}
            maxWidth={'xs'}
            fullWidth={true}
            sx={{ padding: '20px' }}
        >
            <DialogTitle sx={{ padding: '16px 24px 0px' }}>
                {connector.label || Capitalize(connector.name)} Authentication
            </DialogTitle>
            {connectDialogContents}
        </Dialog>
    ) : (
        connectDialogContents
    )
}

export default ConnectDialog
