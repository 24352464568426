import { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Container,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Typography,
} from '@mui/material'
import { useDataProvider, useNotify, useGetMany } from 'react-admin'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React from 'react'
import { isEmpty, get } from 'lodash-es'
import { Capitalize } from '@thefront/pandipackV2'
// TODO this is custom for kombo..
import { showKomboConnect } from '@kombo-api/connect'
import { POPUP_WINDOW_PARAMS } from '../../themeConfig'
import ConnectDialog from './ConnectDialog'

export const ExternalConnectorForm = ({
    extraTenantInfo,
    tenant,
    webhookUrl,
    connector,
    oAuth2Link,
}) => {
    const notify = useNotify()
    const dataProvider = useDataProvider()
    const [modalError, setModalError] = useState('')
    const [connected, setConnected] = useState(
        isConnected(tenant, extraTenantInfo.connector_name)
    )

    const postMessage = (message) => {
        const dest = window.opener ?? window.parent
        dest.postMessage(
            {
                message: message,
                webhook_url: webhookUrl,
                tenant: {
                    ...tenant,
                    integration: {
                        id: tenant.integration.id,
                        name: tenant.integration.name,
                    },
                },
            },
            '*'
        )
    }

    const connectBasic = (connectorName, values) => {
        setModalError('')
        dataProvider
            .AUTHOR('author/callback/basic', {
                data: {
                    connector_name: connectorName,
                    integration_name: tenant.integration.name,
                    tenant_id: tenant.id,
                    secrets: values,
                },
            })
            .then((res) => {
                console.log('Connect success')
            })
            .catch((err) => {
                console.debug(err)
                setModalError(err)
                postMessage('pandium-error')
            })
    }

    const disconnectRequest = () => {
        dataProvider
            .AUTHOR('author/disconnect_tenant', {
                data: {
                    connector_name: connector.name,
                    integration_name: tenant.integration.name,
                    tenant_id: tenant.id,
                },
            })
            .then(() => {
                console.debug('Disconnect success: ', connector.name)
                notify('Disconnect success!')
                postMessage('pandium-disconnect')
            })
    }

    const connectRequest = (connectorName, form_data = undefined) => {
        setModalError('')
        dataProvider
            .AUTHOR('author/connect_tenant', {
                data: {
                    connector_name: connectorName,
                    integration_name: tenant.integration.name,
                    tenant_id: tenant.id,
                    form_data: form_data,
                    // if the EMP is in a popup, send the current url to be used as the redirect after /callback/oauth2
                    success_url:
                        window.parent !== window ? null : window.location.href,
                },
            })
            .then(async (res) => {
                if (connectorName === 'kombo') {
                    setKomboToken(await showKomboConnect(res.data.link))
                } else if (res.data.link || res.data.installUrl) {
                    // custom linnworks login flow uses installUrl, rest use link
                    console.debug(
                        'Opening new tab... ',
                        res.data.link || res.data.installUrl
                    )
                    if (window.parent !== window) {
                        window.open(
                            res.data.link || res.data.installUrl,
                            null,
                            POPUP_WINDOW_PARAMS
                        )
                    } else {
                        // if the EMP is in a popup, redirect to the oauth link instead of opening another popup
                        window.location.replace(
                            res.data.link || res.data.installUrl
                        )
                    }
                }
            })
            .catch((err) => {
                console.debug(err)
                setModalError(err)
                postMessage('pandium-error')
            })
    }

    // TODO this is custom for kombo..
    const [komboToken, setKomboToken] = useState('')

    useEffect(() => {
        if (!isEmpty(komboToken)) {
            connectBasic(
                connector.name,
                get(tenant, 'integration.name'),
                get(tenant, 'id'),
                { token: komboToken }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [komboToken])

    useEffect(() => {
        setConnected(isConnected(tenant, extraTenantInfo.connector_name))
    }, [tenant, extraTenantInfo.connector_name])

    // on successful connect, post a message to the window that the emp is embedded in
    useEffect(() => {
        if (connected) {
            postMessage('pandium-success')
        }
    }, [connected])

    useEffect(() => {
        //TODO: this is custom for kombo...
        const processEffect = async () => {
            if (!isConnected(tenant, connector.name) && !isEmpty(oAuth2Link)) {
                if (connector?.name === 'kombo') {
                    setKomboToken(
                        await showKomboConnect(
                            oAuth2Link[connector?.name]?.link
                        )
                    )
                } else if (
                    get(oAuth2Link[connector?.name], 'link.status_code') >= 400
                ) {
                    console.debug('ERROR CREATING OAUTH 2 LINK')
                    setModalError('Error, please check credentials.')
                } else {
                    // setOAuth2LinkError(false)
                }
            } else {
                if (
                    !isConnected(tenant, extraTenantInfo.connector_name) &&
                    connector?.name !== 'kombo' &&
                    connector.type !== 'basic' &&
                    get(connector, 'metadata.multi_step') !== true &&
                    ['web', 'hybrid', 'custom'].includes(
                        get(connector, 'metadata.grant_flow')
                    )
                ) {
                    connectRequest(connector.name)
                }
            }
        }
        processEffect()
        // eslint-disable-next-line
    }, [oAuth2Link, connected])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                maxWidth: '696px',
                mx: 'auto',
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    my: '20px',
                }}
            >
                <Paper
                    elevation={2}
                    sx={{ flexGrow: '1', padding: '20px' }}
                    role={'form'}
                >
                    <Box
                        component="img"
                        sx={{
                            display: 'inline-block',
                            maxWidth: '10%',
                            verticalAlign: 'middle',
                            padding: '0 0 0 24px',
                        }}
                        src={connector.logoUrl}
                        alt="Connector logo"
                    />
                    <DialogTitle sx={{ display: 'inline-block' }}>
                        {get(
                            connector,
                            'label',
                            Capitalize(get(connector, 'name'))
                        )}{' '}
                        Authentication
                    </DialogTitle>
                    {!connected ? (
                        <ConnectDialog
                            close={() => null}
                            open={true}
                            connectorName={connector.name}
                            connector={connector}
                            tenant={tenant}
                            oAuth2Link={oAuth2Link}
                            modalError={modalError}
                            modalLoading={false}
                            connectRequest={connectRequest}
                            connectBasic={connectBasic}
                            mode="external"
                        />
                    ) : (
                        <>
                            <DialogContent
                                sx={{ overflow: 'hidden' }}
                                role="formContent"
                            >
                                <Typography
                                    sx={{
                                        textAlign: 'center',
                                        marginTop: '25px',
                                    }}
                                >
                                    Account connected successfully!
                                </Typography>
                            </DialogContent>
                            <DialogActions sx={{ justifyContent: 'center' }}>
                                <Button onClick={disconnectRequest}>
                                    Reconnect
                                </Button>
                            </DialogActions>
                        </>
                    )}
                </Paper>
            </Container>
        </Box>
    )
}

const isConnected = (tenant, connectorName) => {
    const connectionStatus =
        tenant.status.auth?.[connectorName.toUpperCase() + '_AUTH_STATUS']
    if (connectionStatus === 'Connected') {
        return true
    }
    return false
}

ExternalConnectorForm.propTypes = {
    tenant: PropTypes.any.isRequired,
}

const ExternalConnectorFormWrapper = (props) => {
    const dataProvider = useDataProvider()
    const [connector, setConnector] = useState()
    const [tenant, setTenant] = useState()
    const [webhookUrl, setWebhookUrl] = useState()

    // tenantId will be in url params if this page is being
    // accessed via /connector/tenants/:tenantId/edit/connection-form
    const { tenantId } = useParams()

    const runAuthDialogQuery =
        window.location.pathname.split('/')[
            window.location.pathname.split('/').length - 1
        ] !== 'connection-form'
    const {
        data: connectorData,
        loading: connectorLoading,
        error: connectorError,
    } = useGetMany('connectors', {
        ids: [props.extraTenantInfo.connector_name],
    })

    useEffect(() => {
        if (!isEmpty(connectorData)) {
            setConnector(connectorData[0])
        }
    }, [connectorData])

    const tenantCallback = (event) => {
        if (runAuthDialogQuery) {
            setTenant(event.payload.tenant)
            setWebhookUrl(event.payload.webhookUrl)
        } else {
            setTenant(event.payload)
        }
    }

    useEffect(() => {
        let subscribeUrl
        if (runAuthDialogQuery) {
            let integrationName = props?.extraTenantInfo?.integration_name
            let token = props?.extraTenantInfo?.token
            subscribeUrl = token
                ? `authdialog/${integrationName}?token=${token}`
                : `authdialog/${integrationName}`
        } else {
            subscribeUrl = `tenants/${tenantId}`
        }
        dataProvider.subscribe(subscribeUrl, tenantCallback)
        return () => dataProvider.unsubscribe(subscribeUrl, tenantCallback)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // set the tab/popup title
    useEffect(() => {
        document.title = connector
            ? connector.label + ' Authentication'
            : 'Untitled'
    }, [connector])

    return connectorLoading ? (
        <CircularProgress
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
            }}
        />
    ) : connectorError ? (
        <div>Error getting Data</div>
    ) : !isEmpty(tenant) && !isEmpty(connector) ? (
        <ExternalConnectorForm
            connector={connector}
            tenant={tenant}
            webhookUrl={webhookUrl}
            oAuth2Link={props.oAuth2Link}
            extraTenantInfo={props.extraTenantInfo}
        />
    ) : (
        <CircularProgress
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
            }}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        oAuth2Link: state.connector,
        extraTenantInfo: state.user.xti,
    }
}

export default connect(mapStateToProps)(ExternalConnectorFormWrapper)
